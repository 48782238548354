.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.font-important {
  font-size: 100% !important;
  line-height: 1.15 !important;
}

.margin-top-0-important {
  margin-top: 0 !important;
}
