p {
  line-height: 1.4;
}

.logo {
  margin-right: 0.5rem;
  max-width: 2rem;
  width: auto;
}

@media (min-width: 64em) {
  .navbar-long {
    width: 50% !important;
  }
}

.usa-button--outline {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #565c65;
  color: #565c65;
}
.usa-button--outline:visited {
  color: #565c65;
}
.usa-button--outline:hover,
.usa-button--outline.usa-button--hover {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #3d4551;
  color: #3d4551;
}
.usa-button--outline:active,
.usa-button--outline.usa-button--active {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #1b1b1b;
  color: #1b1b1b;
}

.width-50 {
  width: 50% !important;
}

.preview-container {
  overflow: auto;
}

.recharts-legend-wrapper {
  top: -24px !important;
}

.overflow-hidden:hover {
  overflow-x: visible;
}
