.modal {
  position: absolute;
  right: 40px;
  bottom: 40px;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-right: 2px solid #333;
  border-bottom: 2px solid #333;
  background: #fff;
  overflow: auto;
  outline: none;
  padding: 20px;
  top: 50%;
  left: 50%;
  width: 512px;
  display: table;
  transform: translate(-50%, -50%);
  -webkit-overflow-scrolling: touch;
  z-index: 100001;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100000;
}
