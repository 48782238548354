.Markdown a {
  color: #565c65 !important;
}

.Markdown a:hover {
  color: #1b1b1b !important;
}

.Markdown a:active {
  color: #1b1b1b !important;
}

.Markdown a:visited {
  color: #1b1b1b !important;
}
