.usa-step-indicator__segment--current::after {
  background-color: #565c65 !important;
}

.usa-step-indicator__segment--complete::after {
  background-color: #1b1b1b !important;
}

.usa-step-indicator--counters .usa-step-indicator__segment--current::before,
.usa-step-indicator--counters-sm .usa-step-indicator__segment--current::before {
  background-color: #565c65 !important;
}

.usa-step-indicator--counters .usa-step-indicator__segment--complete::before,
.usa-step-indicator--counters-sm
  .usa-step-indicator__segment--complete::before {
  background-color: #1b1b1b !important;
}
