.usa-button--base {
  background-color: #565c65 !important;
}
.usa-button--base:hover,
.usa-button--base.usa-button--hover {
  background-color: #3d4551 !important;
}
.usa-button--base:active,
.usa-button--base.usa-button--active {
  background-color: #1b1b1b !important;
}
.usa-button--base:disabled {
  background-color: #c9c9c9 !important;
}
